import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Pagination, Tag, Table } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime, toFixedFunc } from '../../../../helps/HelperFunctions';

const PlayerBetHistory = ({ id }) => {
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);

  const nav = useNavigate();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const getPlayerBetHistory = async (customPage = null, id) => {
    try {
      const path = `/admin/player/${id}/betHistory`;
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      //   console.log('response', res);
      if (res && res?.data && res?.data?.length) {
        console.log(res.data);
        const finalArray = res?.data?.map((user, index) => ({
          key: index,
          id: user?.id,
          round_reference: user?.round_reference ? user?.round_reference : '-',
          openedOn: convertUTCToLocalTime(user?.started_on),
          closedOn: convertUTCToLocalTime(user?.ended_on),

          // {JSON.parse(record.name)?.en}
          gameName: JSON.parse(user.name)?.en,
          winLoss: user?.win_loss ? toFixedFunc(user.win_loss, process.env.REACT_APP_Decimel_Points) : 0,
          // resultStatus: checkBetStatus(user.status),
          resultStatus: user?.status ? user?.status : '-',
          totalBetAmount: user.bets_sum_total_bet_amount
            ? toFixedFunc(user.bets_sum_total_bet_amount, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalTurnover: user.total_turnovers
            ? toFixedFunc(user.total_turnovers, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalValidBet: user.total_valid_bets
            ? toFixedFunc(user.total_valid_bets, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalWinAmount: user.total_win_amount
            ? toFixedFunc(user.total_win_amount, process.env.REACT_APP_Decimel_Points)
            : 0
        }));
        setData(finalArray);
        setCurrentpage(res?.current_page);
        setTotal(res?.total);
        setPerpage(res?.per_page);
        setNextPage(res?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  console.log('test', Data);

  useEffect(() => {
    getPlayerBetHistory(activepage, id);
  }, []);
  console.log(Data);

  const columns = [
    {
      title: 'Opened On',
      dataIndex: 'openedOn',
      key: 'openedOn',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
    {
      title: 'Closed On',
      dataIndex: 'closedOn',
      key: 'closedOn',
      render: (time) => <span>{time === convertUTCToLocalTime(null) ? '_' : convertUTCToLocalTime(time)}</span>
    },
    {
      title: 'Reference ID',
      dataIndex: 'round_reference',
      key: 'round_reference'
    },
    // {
    //   title: 'Platform',
    //   dataIndex: 'platform',
    //   key: 'platform'
    // },
    // {
    //   title: 'Game',
    //   dataIndex: 'gameName',
    //   key: 'gameName',
    //   render: (item) => {
    //     return <div style={{ whiteSpace: 'pre-wrap' }}>{item}</div>;
    //   }
    // },
    // {
    //   title: 'Player Name',
    //   dataIndex: 'playerName',
    //   key: 'playerName'
    // },
    // {
    //   title: 'Agent ID',
    //   dataIndex: 'agentId',
    //   key: 'agentId'
    // },
    {
      title: 'Win/Loss (amount)',
      dataIndex: 'winLoss',
      key: 'winLoss',
      render: (item) => {
        const cleanedItem = item.replace(/[^0-9.-]/g, '');
        const value = Number(cleanedItem);
        const color = isNaN(value) || value >= 0 ? 'inherit' : 'red';

        return <span style={{ color }}>{isNaN(value) ? 'Invalid value' : value}</span>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'resultStatus',
      key: 'resultStatus'
    },
    {
      title: 'Total Bet Amount',
      dataIndex: 'totalBetAmount',
      key: 'totalBetAmount'
    },

    {
      title: 'Total Turnover',
      dataIndex: 'totalTurnover',
      key: 'totalTurnover'
    },

    {
      title: 'Total Valid Bets',
      dataIndex: 'totalValidBet',
      key: 'totalValidBet'
    },
    {
      title: 'Total Win Amount',
      dataIndex: 'totalWinAmount',
      key: 'totalWinAmount'
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Image
    //       src={ViewImg}
    //       style={{ color: 'rgb(64, 81, 137)', cursor: 'pointer' }}
    //       preview={false}
    //       onClick={() => navigate(`/reports/bet-history/bet-details/${record.id}`)}
    //     />
    //   ),
    // },
  ];

  const onChangepage = (page) => {
    setActivepage1(page);
    getPlayerBetHistory(page, id);
  };

  return (
    <>
      <Table
        pagination={false}
        style={{
          width: '100%',
          marginTop: '30px',
          overflowX: 'auto',
          overflowY: 'scroll'
        }}
        size="small"
        columns={columns}
        bordered
        scroll={{
          x: 'max-content'
        }}
        dataSource={Data}
        loading={loading}
      />
      <Pagination
        defaultCurrent={1}
        current={activepage}
        onChange={onChangepage}
        total={total}
        responsive={true}
        pageSize={perpage}
      />
    </>
  );
};

export default PlayerBetHistory;
