const constants = {
  // BASE_URL1: `https://api-bw9vndemo.iegaming.io/api`,
  // BASE_URL2: `https://api-bw9vndemo.iegaming.io`,

  BASE_URL1: `https://wmapis-staging.iegaming.io/api`,
  BASE_URL2: `https://wmapis-staging.iegaming.io`

  // BASE_URL1: `https://wmapis-master.iegaming.io/api`,
  // BASE_URL2: `https://wmapis-master.iegaming.io`

  // BASE_URL1:`https://api.bowmproject.com/api`,
  // BASE_URL2:`https://api.bowmproject.com`,
};

export { constants };
