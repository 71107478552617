import React from 'react';
import { Image, Table } from 'antd';
import ViewImg from '../../../src/images/View.png';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime } from '../../helps/HelperFunctions';
// import LangContext from "../../helps/contexts/lang-context";
// import { useContext } from "react";

const BetHistorytable = ({ data, loading }) => {
  // const ctx = useContext(LangContext);
  // const lang = ctx.lang;
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Bet time/settle time',
      dataIndex: 'openedOn',
      key: 'openedOn',
      render: (time, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{convertUTCToLocalTime(record.openedOn)}</div>
            <div>{convertUTCToLocalTime(record.closedOn)}</div>
          </div>
        );
      }
    },
    // {
    //   title: "Closed On",
    //   dataIndex: "closedOn",
    //   key: "closedOn",
    //   render: (time) => <span>{time === convertUTCToLocalTime(null) ? '_' : convertUTCToLocalTime(time)}</span>,
    // },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
    },
    {
      title: 'Ticket ID/Ref Ticket ID',
      dataIndex: 'round_reference',
      key: 'round_reference',
      render: (time, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{record?.id}</div>
            <div>{record?.round_reference}</div>
          </div>
        );
      }
    },
    {
      title: 'Game Type',
      dataIndex: 'type_name',
      key: 'type_name',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    {
      title: 'Platform/Game',
      dataIndex: 'platform',
      key: 'platform',
      render: (item, record) => {
        let decodedContent;

        try {
          decodedContent =
            typeof record?.betting_content === 'string' ? JSON.parse(record?.betting_content) : record?.betting_content;
        } catch (error) {
          console.error('Error parsing betting_content:', error);
          decodedContent = null;
        }

        return (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            <div>{record?.platform}</div>

            {decodedContent ? (
              Array.isArray(decodedContent) ? (
                <div>
                  <div>{decodedContent[0]?.sportsType}</div>
                </div>
              ) : (
                <div>{decodedContent?.sportsType}</div>
              )
            ) : (
              <div>{record?.gameName}</div>
            )}
          </div>
        );
      }
    },
    {
      title: 'Player ID/Reference ID',
      dataIndex: 'round_reference',
      key: 'round_reference',
      render: (time, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{record?.player_id}</div>
            <div>{record?.user_name}</div>
          </div>
        );
      }
    },
    {
      title: 'Match ID/Game ID',
      dataIndex: 'match_id',
      key: 'match_id',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    {
      title: 'Betting Content',
      dataIndex: 'betting_content',
      key: 'betting_content',
      render: (item, record) => {
        if (!record?.betting_content) {
          return '-';
        }

        let decodedContent = record?.betting_content;
        if (typeof decodedContent === 'string') {
          try {
            decodedContent = JSON.parse(decodedContent);
          } catch (e) {
            return '-';
          }
        }

        let market = record?.market;
        if (typeof market === 'string') {
          try {
            market = JSON.parse(market);
          } catch (e) {
            market = null;
          }
        }

        const isArray = Array.isArray(decodedContent);

        const formatLiveType = (liveType) =>
          liveType?.toLowerCase() === 'today' || liveType?.toLowerCase() === 'early' ? 'prematch' : liveType;

        const renderBettingItem = (content, market) => {
          const liveType = formatLiveType(content?.liveType);
          const marketName = market?.marketName || '';
          const pos = content?.pos || '';

          let posDisplay;
          if (marketName?.includes('1X2') || marketName?.includes('Moneyline')) {
            switch (pos) {
              case '1':
                posDisplay = content?.homeTeamName || 'Home Team';
                break;
              case '2':
                posDisplay = content?.awayTeamName || 'Away Team';
                break;
              case 'X':
                posDisplay = 'DRAW';
                break;
              default:
                posDisplay = pos;
            }
          } else {
            posDisplay = pos;
          }

          return (
            <div style={{ marginBottom: '10px' }}>
              <div>
                {liveType && <span>{liveType}; </span>}
                {content?.tournamentName && <span>{content.tournamentName}</span>}
              </div>
              <div>
                <span>
                  ({content?.homeTeamName} v {content?.awayTeamName})
                </span>
              </div>
              <div>
                <div>
                  {content?.ticketType && <span>{content.ticketType}; </span>}
                  {Array.isArray(market)
                    ? market.map((marketItem, index) => <span key={index}>{marketItem?.marketName}:</span>)
                    : market?.marketName && <span>{market?.marketName}:</span>}
                  <span>{posDisplay}</span>
                  {content?.liveScore ? <span>({content?.liveScore})</span> : <span></span>}
                </div>
              </div>
            </div>
          );
        };

        return (
          <div>
            {isArray
              ? decodedContent.map((content, index) => (
                  <div key={index}>{renderBettingItem(content, Array.isArray(market) ? market[index] : market)}</div>
                ))
              : renderBettingItem(decodedContent, market)}
          </div>
        );
      }
    },

    {
      title: 'Status',
      dataIndex: 'resultStatus',
      key: 'resultStatus'
    },
    {
      title: 'Bet Amount',
      dataIndex: 'totalBetAmount',
      key: 'totalBetAmount'
    },
    {
      title: 'Valid Bets',
      dataIndex: 'totalValidBet',
      key: 'totalValidBet'
    },
    {
      title: 'Win Amount',
      dataIndex: 'totalWinAmount',
      key: 'totalWinAmount'
    },
    {
      title: 'W/L amount',
      dataIndex: 'winLoss',
      key: 'winLoss',
      render: (item) => {
        let numericItem;
        if (typeof item === 'string') {
          numericItem = Number(item.replace(/,/g, ''));
        } else {
          numericItem = Number(item);
        }

        const color = numericItem < 0 ? 'red' : '';

        return (
          <span style={{ color }}>
            {numericItem.toLocaleString()}
            {/* {console.log(numericItem)}  */}
          </span>
        );
      }
    },
    {
      title: 'Odds',
      dataIndex: 'odds',
      key: 'odds',
      render: (text, record) => {
        return record?.total_odds !== null ? '-' : text ? text : '-';
      }
    },
    {
      title: 'Total Odds',
      dataIndex: 'total_odds',
      key: 'total_odds',
      render: (text) => {
        return text !== null ? text : '-';
      }
    },
    {
      title: 'Market',
      dataIndex: 'market',
      key: 'market',
      render: (item, record) => {
        if (!record?.market) {
          return '-';
        }

        const { marketId, marketName } = record.market;

        return (
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {/* {marketId ? <span>{marketId}</span> : '-'} */}
            {marketName ? <span>{marketName}</span> : '-'}
          </span>
        );
      }
    },
    {
      title: 'Agent ID',
      dataIndex: 'agentId',
      key: 'agentId',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    {
      title: 'IP',
      dataIndex: 'ip_address',
      key: 'ip_address',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    {
      title: 'Update time',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    // {
    //   title: "Player Name",
    //   dataIndex: "playerName",
    //   key: "playerName",
    // },

    // {
    //   title: "Total Turnover",
    //   dataIndex: "totalTurnover",
    //   key: "totalTurnover",

    // },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Image
          src={ViewImg}
          style={{ color: 'rgb(64, 81, 137)', cursor: 'pointer' }}
          preview={false}
          onClick={() => navigate(`/reports/bet-history/bet-details/${record.id}`)}
        />
      ),
      fixed: 'right'
    }
  ];

  return (
    <Table
      scroll={{
        x: 'max-content'
      }}
      loading={loading}
      columns={columns}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
    />
  );
};

export default BetHistorytable;
